import React from "react";
import ICardWidgetProps from "../../../../../../model/interface/dataStorage/card/ICardWidgetProps";
import {Divider, Image, Rate, Row, Tag, Typography} from "antd";
import moment from "moment";
import ICardWidgetScalarField from "../../../../../../model/interface/dataStorage/card/ICardWidgetScalarField";
import IRepositoryService from "../../../../../../model/interface/IRepositoryService";
import {connect, RootStateOrAny} from "react-redux";
import selectors from "../../../../../../redux/selectors";
import Utils from "../../../../../../utils";
import DataStorageHelper from "../../../../../../utils/DataStorageHelper";
import IPresenter from "../../../../../../model/interface/dataStorage/IPresenter";
import IconBuilder from "../../../../../../utils/IconBuilder";
import FormInlineEdit from "../../../../form/FormInlineEdit";
import ConditionEditor from "../../../form/FormElement/optionEditor/ConditionEditor";
import settingsService from "../../../../../../model/service/SettingsService";
import ScalarFieldEditor from "./editor/ScalarFieldEditor";
import Emoji from "../../field/presenters/Emoji";

const {Text, Title} = Typography

interface IProps extends ICardWidgetProps<ICardWidgetScalarField> {
    findServiceByClassName: (name: string) => IRepositoryService
}

interface IState {
    inlineDisabled?: boolean
}

class ScalarFieldWidget extends React.Component<IProps, IState> {


    constructor(props: IProps, context: any) {
        super(props, context);
        this.state = {
            inlineDisabled: false
        }
    }

    static TEXT = 'text'
    static HTML = 'html'
    static NUMBER = 'number'
    static BOOLEAN = 'boolean'
    static RATE = 'rate'
    static COMPOSITE_APPROVAL = 'composite-approval'
    static DATE = 'date'
    static RELATION = 'relation'
    static BASE_64 = 'base64'
    static PHONE_NUMBER = 'phone-number'
    static EMOJI = 'emoji'

    static TYPES = [
        {value: ScalarFieldWidget.TEXT, label: 'Text'},
        {value: ScalarFieldWidget.HTML, label: 'HMTL'},
        {value: ScalarFieldWidget.NUMBER, label: 'Číslo'},
        {value: ScalarFieldWidget.BOOLEAN, label: 'Logická hodnota'},
        {value: ScalarFieldWidget.DATE, label: 'Datum'},
        {value: ScalarFieldWidget.RELATION, label: 'Vazba'},
        {value: ScalarFieldWidget.BASE_64, label: 'Base64 (zdrojový text obrázku)'},
        {value: ScalarFieldWidget.RATE, label: 'Hodnocení'},
        {value: ScalarFieldWidget.COMPOSITE_APPROVAL, label: 'Schválení'},
        {value: ScalarFieldWidget.PHONE_NUMBER, label: 'Telefonní číslo'},
        {value: ScalarFieldWidget.EMOJI, label: 'Emoji'}
    ] as { value: string, label: string }[]

    static getDerivedStateFromProps(props: IProps) {
        const {functions, preview, options} = props
        if (preview && functions.getValue) {
            return {inlineDisabled: ConditionEditor.evaluateConditions(options.inPlaceConditions, functions.getValue)}
        }
        return null
    }

    componentDidMount() {
        const state = ScalarFieldWidget.getDerivedStateFromProps(this.props)
        this.setState({inlineDisabled: !!state?.inlineDisabled})
    }

    renderByType(props: IProps) {
        const {editor, value, functions, resource, match, history, options} = props
        const {
            suffix,
            prefix,
            showLabel,
            label,
            type,
            titleLevel,
            inPlaceDataAction,
            inPlaceType,
            inPlaceAction,
            inPlaceFieldOptions
        } = options

        const {inlineDisabled} = this.state
        const action = inPlaceAction ? functions.getContentType().actions.find(action => action.uuid === inPlaceAction) : undefined
        const field = functions.getField(options.field)
        const content = field ? (
            <Row className={'w-100'} align={"middle"}>
                {[undefined, true].includes(showLabel) &&
                    <Text className={'mr-2'} strong>{label}:</Text>}
                {prefix && <Text className={'mr-2'}>{prefix}</Text>}
                {props.options.inPlaceEdit && !inlineDisabled && FormInlineEdit.isAllowed(resource, action) ? (
                    <FormInlineEdit resource={resource || {}}
                                    fieldOptions={inPlaceFieldOptions || field.options}
                                    onUpdate={() => functions.updateValue(this.props.id, value)}
                                    type={inPlaceType} field={field} contentType={functions.getContentType()}
                                    doAction={inPlaceDataAction} match={match} history={history} preview={editor}
                                    onFinish={(result) => functions.onFinishAction?.(result)}/>
                ) : (
                    <>
                        {editor ? ScalarFieldWidget.renderPreview(type || ScalarFieldEditor.detectType(field), value, {...field.options, ...props.options}) : this.renderBase(props)}
                    </>
                )}
                {suffix && <Text>{suffix}</Text>}
            </Row>
        ) : 'Chyba konfigurace'
        return titleLevel && +titleLevel ?
            <Title level={+titleLevel as 1 | 2 | 3} style={{color: settingsService.getThemeColor()}}>{content}</Title>
            : content
    }

    renderBase(props: IProps) {
        if (props.resource) {
            const className = props.resource!._class
            if (className) {
                const service = props.findServiceByClassName(className)

                const field = service.getFields().find(field => field.uuid === props.options.field)

                if (!field) {
                    console.error(props.options, service.getRecordClassName(), service.getFields())
                    return 'Chyba konfigurace #1';
                }
                const value = props.resource[field.name]
                if (!value) {
                    return <Text disabled>{props.options.emptyText}</Text>
                }
                let presenter = undefined
                if (field.mode === 'relation') {
                    const fieldService = props.findServiceByClassName(field.targetEntity!)
                    presenter = props.options.relationPresenter ? fieldService.getPresenter(props.options.relationPresenter) : fieldService.getDefaultPresenter()
                }
                if (!presenter) {
                    presenter = {
                        type: 'label',
                        name: 'default',
                        label: 'Výchozí',
                        options: {
                            label: "#" + field.name
                        }
                    } as IPresenter
                }
                presenter.options = {...props.options, ...presenter.options}
                return DataStorageHelper.buildFieldValue(field!, props.resource, props.history, presenter || undefined)
            }
        }

        return <Text disabled>{props.options.emptyText}</Text>
    }


    static renderBoolean(value: any, booleanTrue ?: string, booleanFalse ?: string) {
        const booleanTrueText = booleanTrue ? booleanTrue : 'Povoleno'
        const booleanFalseText = booleanFalse ? booleanFalse : 'Zakázáno'
        return <Tag color={value ? 'success' : 'error'}>{value ? booleanTrueText : booleanFalseText}</Tag>
    }

    static renderPreview(type: string, value?: any, options?: ICardWidgetScalarField) {
        switch (type) {
            case ScalarFieldWidget.TEXT:
                return value !== undefined ? value : "lorem ipsum"
            case ScalarFieldWidget.HTML:
                return value !== undefined ? value : (<><i>lorem</i> <strong>ipsum</strong></>)
            case ScalarFieldWidget.NUMBER:
            case ScalarFieldWidget.PHONE_NUMBER:
                return value !== undefined ? value : 123
            case ScalarFieldWidget.BOOLEAN:
                return value !== undefined ? this.renderBoolean(value) : (
                    <Row align={"middle"} justify={"space-between"} className={'h-100 d-inline-flex'}>
                        <Tag className={'m-0'} color={'success'}>Povoleno</Tag>
                        <Divider type={"vertical"}/>
                        <Tag className={'m-0'} color={'error'}>Zakázáno</Tag>
                    </Row>
                )
            case ScalarFieldWidget.DATE:
                return moment(value).format(options?.dateFormat ? options?.dateFormat : 'LLL')
            case ScalarFieldWidget.RELATION:
                return value !== undefined ? value : 'lorem ipsum'
            case ScalarFieldWidget.BASE_64:
                return <Row justify={"center"} align={'middle'} className={'w-100 border rounded p-2'}>
                    <Image
                        className={"d-block"}
                        fallback={Utils.imageFallback()} height={60} src={''}
                    />
                </Row>
            case ScalarFieldWidget.RATE:
                return <Rate disabled={true} character={options?.rateIcon && IconBuilder(options.rateIcon)}
                             count={options?.rateLength || 5} value={value !== undefined ? value : 3}/>
            case ScalarFieldWidget.COMPOSITE_APPROVAL:
                return value !== undefined ? value : (<Tag className={'m-0'} color={'success'}>Schváleno</Tag>)
            case ScalarFieldWidget.EMOJI:
                return <Emoji options={{...options as any}} value={'🫠'}/>
            default:
                return `Invalid scalar field type ${type}`
        }
    }

    render() {
        return (
            <Row className={'mb-2'}>
                {this.renderByType(this.props)}
            </Row>
        );
    }

}

const mapStateToProps = (state: RootStateOrAny) => {
    return {
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name)
    }
}

export default connect(mapStateToProps)(ScalarFieldWidget)
import {Col, Collapse, Row, Typography} from 'antd';
import React from "react";
import IField from "../../../../../model/interface/dataStorage/IField";

interface IProps {
    field: IField
}
interface IState {

}

class FieldCollapseInfo extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    render() {
        const {field} = this.props
        return <Collapse className={'mb-3'}>
            <Collapse.Panel header={`Field [${field.name}]`} key="1">
                <Row gutter={10}>
                    <Col span={8}><Typography.Text type="secondary">Titulek: </Typography.Text></Col>
                    <Col span={16}><Typography.Text type="secondary">{field.label}</Typography.Text></Col>
                    <Col span={8}><Typography.Text type="secondary">Nazev: </Typography.Text></Col>
                    <Col span={16}><Typography.Text type="secondary">{field.name}</Typography.Text></Col>
                    <Col span={8}><Typography.Text type="secondary">Mod: </Typography.Text></Col>
                    <Col span={16}><Typography.Text type="secondary">{field.mode}</Typography.Text></Col>
                    <Col span={8}><Typography.Text type="secondary">Typ: </Typography.Text></Col>
                    <Col span={16}><Typography.Text type="secondary">{field.type}</Typography.Text></Col>
                    <Col span={8}><Typography.Text type="secondary">ID: </Typography.Text></Col>
                    <Col span={16}><Typography.Text type="secondary">{field.id}</Typography.Text></Col>
                    <Col span={8}><Typography.Text type="secondary">Unit: </Typography.Text></Col>
                    <Col span={16}><Typography.Text type="secondary">{field.unit}</Typography.Text></Col>
                </Row>
            </Collapse.Panel>
        </Collapse>
    }
}

export default FieldCollapseInfo
import React from "react"
import IBaseProps from "model/interface/IBaseProps";
import ViewTable from "./ViewTable";
import selectors from "../../../redux/selectors";
import {connect, RootStateOrAny} from "react-redux";
import ViewCalendar from "./ViewCalendar";
import IRestServiceFilters from "../../../model/interface/api/IRestServiceFilters";
import ViewsSettingsService from "../../../model/service/dataStorage/view/ViewsSettingsService";
import {API_FILTER_TYPE} from "../../../model/constants/ApiConstant";
import IViewSettingsStructure from "../../../model/interface/dataStorage/view/settings/IViewSettingsStructure";
import {ViewSettings} from "./ViewSettings";
import {ISetupState} from "../../../redux/reducers/Setup";
import IUser from "../../../model/interface/security/IUser";
import {Row, Spin, Typography} from "antd";
import IViewStateStructure from "../../../model/interface/dataStorage/view/settings/IViewStateStructure";
import IViewSettings from "../../../model/interface/dataStorage/view/IViewSettings";
import {
    VIEW_TYPE_CALENDAR,
    VIEW_TYPE_CAROUSEL,
    VIEW_TYPE_GRAPH,
    VIEW_TYPE_PIVOT_TABLE,
    VIEW_TYPE_TABLE,
    VIEW_TYPE_TIMELINE
} from "../../../model/service/dataStorage/ViewTypesService";
import ViewGraph from "./ViewGraph";
import ViewTimeline from "./ViewTimeline";
import ViewsService from "../../../model/service/dataStorage/ViewsService";
import IContentType from "../../../model/interface/dataStorage/IContentType";
import IViewUnit from "../../../model/interface/dataStorage/IViewUnit";
import IView from "../../../model/interface/dataStorage/IView";
import IRestServiceOrders from "../../../model/interface/api/IRestServiceOrders";
import IField from "../../../model/interface/dataStorage/IField";
import {IActionResult} from "../../../model/service/dataStorage/ActionsService";
import ILabelValue from "../../../model/interface/util/ILabelValue";
import IRepositoryService from "../../../model/interface/IRepositoryService";
import IViewItem from "../../../model/interface/dataStorage/view/IViewItem";
import Utils from "../../../utils";
import ViewPivotTable from "./ViewPivotTable";
import IViewPermissions from "../../../model/interface/dataStorage/view/IViewPermissions";
import ViewCarousel from "./ViewCarousel";
import UsersService from "../../../model/service/security/UsersService";

interface IState {
    loading: boolean
    state: IViewStateStructure
    showSettings: boolean
    showBaseSettings: boolean
    userSettings?: IViewSettings
    baseSettings?: IViewSettings
    permissions: IViewPermissions
    subColumnsValues: { [field: string]: ILabelValue[] }
}

export interface IBaseViewProps extends IBaseProps {
    settings: IViewSettingsStructure
    permissions: IViewPermissions
    state: IViewStateStructure
    filters?: IRestServiceFilters
    showSettings: () => void
    showBaseSettings: () => void
    saveState: (state: IViewStateStructure) => Promise<IViewStateStructure>
    viewUnit: IViewUnit,
    view: IView,
    title?: string,
    titleLevel?: 1 | 2 | 3 | 4 | 5,
    allowSettings?: boolean
    allowExport?: boolean
    reload?: boolean,
    onFinishAction?: (result?: IActionResult) => Promise<void>,
    subColumnsValues: { [field: string]: ILabelValue[] }
    updateSettings: (settings: IViewSettingsStructure | null) => Promise<void>
    standalone?: boolean,
    insideTab?: boolean
}

interface IProps extends IBaseProps {
    uuid?: string
    resource: IViewUnit
    filters?: IRestServiceFilters
    user: IUser
    view: IView
    preview?: boolean,
    standalone?: boolean,
    allowSettings?: boolean,
    allowExport?: boolean,
    title?: string,
    titleLevel?: 1 | 2 | 3 | 4 | 5,
    findContentTypeByUuid: (uuid: string) => IContentType,
    onFinishAction?: (result?: IActionResult) => Promise<void>
    reload?: boolean
    findServiceByClassName: (name: string) => IRepositoryService
}

class ViewUnit extends React.Component<IProps, IState> {

    constructor(props: Readonly<IProps>) {
        super(props);
        this.state = {
            loading: true,
            showSettings: false,
            showBaseSettings: false,
            state: {},
            permissions: {},
            subColumnsValues: {}
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.resource.uuid !== this.props.resource.uuid) {
            this.load()
        }
    }

    componentDidMount() {
        this.load()
    }

    load = () => {
        const {user} = this.props
        const viewUnit = this.getViewUnit()
        if (user && viewUnit && viewUnit.id) {
            this.setState({loading: true})
            let promises: Promise<void>[] = []
            this.loadSubColumnsValues(promises)
            promises.push(
                ViewsSettingsService.collectionList(viewUnit.id, {
                    filters: {
                        1: {
                            type: 'or',
                            children: {
                                1: {
                                    field: 'user',
                                    value: user.id,
                                    type: API_FILTER_TYPE.EQUAL // user specific settings
                                },
                                2: {
                                    field: 'user',
                                    type: API_FILTER_TYPE.IS_NULL // default settings by administrator
                                }
                            }
                        }
                    }
                }).then(({results}) => {
                    const baseSettings = results.find(result => result.user === null || result.user === undefined)
                    const userSettings = results.find(result => (result.user && result.user === this.props.user.uuid))
                    this.setState({
                        state: userSettings?.state || baseSettings?.state || {},
                        baseSettings,
                        userSettings
                    })
                })
            )

            // TODO - get filters applied for its content types - need to refactor conditions in card setup
            let filtersForPermissions = {} as any
            this.getViewUnit().contentTypes.forEach(contentTypeUuid => {
                const contentType = this.props.findContentTypeByUuid(contentTypeUuid)
                filtersForPermissions[contentType.fullClassName] = this.props.filters
            })
            promises.push(
                ViewsService.fetchPermissions(viewUnit.view, {filters: filtersForPermissions}).then(({permissions}) => {
                    this.setState({permissions})
                })
            )
            Promise.all(promises).then(() => {
                this.setState({loading: false})
            })
        } else {
            this.setState({loading: false})
        }
    }

    loadSubColumnsValues(promises: Promise<void>[]) {
        const {resource, findServiceByClassName, findContentTypeByUuid} = this.props
        resource.items.forEach(item => {
                if (item.options?.subColumnField) {
                    const field = findContentTypeByUuid(resource.contentTypes[0])?.fields.find(f => f.uuid === item.field)
                    const service = field?.targetEntity ? findServiceByClassName(field.targetEntity) : undefined
                    const target = service?.getFields().find(f => f.name === item.options?.subColumnField)?.targetEntity
                    const promise = target && findServiceByClassName(target).choiceList(item.options.subColumnPresenter || 'default', {
                        filters: {
                            only: {
                                type: item.options.subColumnValues?.length ? API_FILTER_TYPE.IN : API_FILTER_TYPE.IS_NOT_NULL,
                                field: 'uuid',
                                value: item.options.subColumnValues
                            }
                        }
                    }).then(({results}) => field && this.setState(state => ({
                        subColumnsValues: {
                            ...state.subColumnsValues,
                            [field.name]: Object.entries(results).map(([value, label]) => ({value, label}))
                        }
                    })))
                    promise && promises.push(promise)
                }
            }
        )
    }


    saveState = (newState: IViewStateStructure) => {
        const {user} = this.props
        const {userSettings, baseSettings, state} = this.state
        const view = this.getViewUnit()

        if (!Object.keys(newState).length || JSON.stringify(state) === JSON.stringify(newState)) {
            return Promise.resolve(state)
        }

        if (user && view && userSettings && userSettings.id) {
            return new Promise<IViewStateStructure>(resolve => {
                ViewsSettingsService.resourceUpdate(view.id, userSettings.id!, {
                    state: newState,
                } as unknown as IViewSettings).then(() => resolve(newState))
            })
        }
        return new Promise<IViewStateStructure>(resolve => {
            if ((!userSettings || !userSettings.id) && baseSettings) {
                this.updateSetting(baseSettings.settings).then(() => {
                    this.saveState(newState).then(state => resolve(state))
                })
            }
        })
    }

    updateSetting = (settings: IViewSettingsStructure | null): Promise<void> => {
        if (!settings) {
            this.setState({showSettings: false})
            return Promise.resolve()
        }
        let promise: Promise<any>
        const {userSettings} = this.state
        const view = this.getViewUnit()
        const {user} = this.props
        if (userSettings && userSettings.id) {
            promise = ViewsSettingsService.resourceUpdate(view.id, userSettings.id, {
                settings,
                enabled: true
            } as IViewSettings)
        } else {
            promise = ViewsSettingsService.collectionCreate(view.id, {
                settings,
                enabled: true,
                user: user.id as number
            })
        }
        return promise.then((resource) => {
            this.setState({userSettings: resource, showSettings: false})
        })
    }

    closeUserSettings() {
        this.setState({showSettings: false})
    }

    resetUserSettings() {
        const {userSettings} = this.state
        if (userSettings && userSettings.id) {
            const view = this.getViewUnit()
            return ViewsSettingsService.resourceDelete(view.id, userSettings.id).then(() => {
                this.setState({userSettings: undefined, showSettings: false})
            })
        } else {
            return new Promise<void>(
                resolve => this.setState({userSettings: undefined, showSettings: false}, resolve)
            )
        }
    }

    closeBaseSettings() {
        this.setState({showBaseSettings: false})
    }

    updateBaseSetting(settings: IViewSettingsStructure | null): Promise<void> {
        if (!settings) {
            this.setState({showBaseSettings: false})
            return Promise.resolve()
        }
        let promise: Promise<any>
        const {baseSettings} = this.state
        const view = this.getViewUnit()
        if (baseSettings && baseSettings.id) {
            promise = ViewsSettingsService.resourceUpdate(view.id, baseSettings.id, {
                settings: settings,
                enabled: true
            } as unknown as IViewSettings)
        } else {
            promise = ViewsSettingsService.collectionCreate(view.id, {
                settings: settings,
                enabled: true,
            })
        }
        return promise.then((resource) => {
            this.setState({baseSettings: resource, showBaseSettings: false})
        })
    }

    showSettings = () => {
        this.setState({showSettings: true})
    }

    showBaseSettings = () => {
        this.setState({showBaseSettings: true})
    }

    getViewUnit() {
        return this.props.resource
    }

    static getItemSortBy(viewUnit: IViewUnit, field: IField, direction: 'ASC' | 'DESC' = "ASC", prefix = 'order-'): IRestServiceOrders {
        const item = viewUnit.items.find(item => item.field === field.uuid)
        let orders: IRestServiceOrders = {}
        let found = false
        if (item && item.options?.sortBy) {
            (Array.isArray(item.options.sortBy) ? item.options.sortBy : [item.options.sortBy]).forEach((order, index) => {
                if (order.indexOf(field.name) === 0) {
                    orders[prefix + field.name + '_' + index] = {
                        field: order,
                        direction: direction
                    }
                    found = true
                }
            })
        }

        return found ? orders : {['order-' + field.name]: {field: field.name, direction}}
    }

    buildItems(): IViewItem[] {
        const {resource, findContentTypeByUuid} = this.props
        const {subColumnsValues, permissions} = this.state
        const finalItems: IViewItem[] = []

        resource.items.forEach((item) => {
            let subItems: IViewItem[] = []
            let field: IField | undefined
            let contentType: IContentType | undefined
            resource.contentTypes.some(contentTypeUuid => {
                contentType = findContentTypeByUuid(contentTypeUuid)
                field = contentType.fields.find(f => f.uuid === item.field)
                return field
            })
            const {subColumnField} = item.options || {}
            if (field && subColumnField && subColumnsValues[field.name] && field.targetEntity) {
                subColumnsValues[field.name].forEach(column => {
                    subItems.push({
                        ...item,
                        uuid: Utils.uuid(),
                        options: {
                            ...item.options,
                            title: column.label.toString(),
                            subColumnCurrentValue: column.value.toString()
                        }
                    })
                })
            }
            const action = contentType?.actions.find(a => a.uuid === item.accessAction)
            if (contentType && (!action || permissions[contentType.fullClassName]?.[action.name])) {
                finalItems.push(...(subItems.length ? subItems : [item]))
            }
        })
        return finalItems
    }

    render() {
        const {
            loading,
            showSettings,
            showBaseSettings,
            userSettings,
            baseSettings,
            state,
            permissions,
            subColumnsValues
        } = this.state
        const {user, preview, title, titleLevel, match, history, resource, reload} = this.props
        const allowSettings = typeof this.props.allowSettings === 'undefined' ? this.props.resource.options.allowSettings : this.props.allowSettings
        const allowExport = typeof this.props.allowExport === 'undefined' ? this.props.resource.options.allowExport : this.props.allowExport
        let view: JSX.Element = <Typography.Text type={"danger"}>Chyba konfigurace!</Typography.Text>
        const filteredResource = {...resource, items: this.buildItems()}

        const settings = allowSettings ? userSettings || baseSettings : baseSettings
        if (resource) {
            const generalProps = {
                updateSettings: this.updateSetting,
                showSettings: this.showSettings,
                showBaseSettings: this.showBaseSettings,
                saveState: this.saveState,
                settings: preview ? (resource.settings || {}) : (settings ? {
                    ...settings.settings,
                    personal: !!settings.user
                } : {}),
                permissions,
                state,
                viewUnit: filteredResource,
                view: this.props.view,
                title,
                titleLevel,
                allowSettings,
                allowExport,
                reload,
                subColumnsValues,
                insideTab: this.props.view.units.filter(u => u.active).length > 1
            }

            switch (resource.type) {
                case(VIEW_TYPE_TABLE):
                    view = <ViewTable {...this.props} {...generalProps}/>
                    break
                case(VIEW_TYPE_CALENDAR):
                    view = <ViewCalendar {...this.props} {...generalProps}/>
                    break
                case(VIEW_TYPE_GRAPH):
                    view = <ViewGraph {...this.props} {...generalProps}/>
                    break
                case(VIEW_TYPE_TIMELINE):
                    view = <ViewTimeline {...this.props} {...generalProps}/>
                    break
                case(VIEW_TYPE_PIVOT_TABLE):
                    view = <ViewPivotTable {...this.props} {...generalProps}/>
                    break
                case(VIEW_TYPE_CAROUSEL):
                    view = <ViewCarousel {...this.props} {...generalProps}/>
                    break
                // case(VIEW_TYPE_KANBAN):
            }
        }

        return (
            <div className={'mt-1'}>
                <ViewSettings
                    visible={showSettings && user && !loading}
                    match={match}
                    history={history}
                    settings={settings?.settings}
                    view={filteredResource}
                    onClose={() => this.closeUserSettings()}
                    onReset={() => this.resetUserSettings()}
                    onChange={(settings: IViewSettingsStructure | null) => this.updateSetting(settings)}
                />
                <ViewSettings
                    visible={showBaseSettings && UsersService.hasCredential(user, 'configuration') && !loading}
                    match={match}
                    history={history}
                    settings={baseSettings?.settings}
                    view={resource}
                    onClose={() => this.closeBaseSettings()}
                    onChange={(values: IViewSettingsStructure | null) => this.updateBaseSetting(values)}
                />
                {loading ? <Row justify={"center"}><Spin/></Row> : view}
            </div>
        )
    }
}


const mapStateToProps = (state: RootStateOrAny) => {
    const {user} = state.setup as ISetupState
    return {
        findContentTypeByUuid: (uuid: string): IContentType => selectors.contentTypes.findOneBy(state, 'uuid', uuid),
        findServiceByClassName: (name: string) => selectors.services.findOneByFullClassName(state, name),
        user
    }
}

export default connect(mapStateToProps)(ViewUnit)

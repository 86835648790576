import React from "react";
import {Badge, Col, Dropdown, Menu, Row, Tag, Tooltip} from "antd";
import {connect} from 'react-redux'
import {
    CheckOutlined,
    LogoutOutlined,
    QuestionCircleOutlined,
    SettingOutlined,
    TeamOutlined,
    UserSwitchOutlined
} from '@ant-design/icons';
import {disableMasquerade, signOut} from 'redux/actions/Auth';
import IUser from "../../model/interface/security/IUser";
import {Link} from "react-router-dom";
import UserAvatar from "../app/security/UserAvatar";
import IEmployee from "../../model/interface/company/IEmployee";
import EmployeeLabel from "../app/company/employees/EmployeeLabel";
import ScrollContainer from "../shared/scrollContainer/ScrollContainer";
import NavTheme from "./NavTheme";
import LocaleText from "../app/settings/dictionary/LocaleText";
import EmployeesService from "../../model/service/company/EmployeesService";
import Button from "../shared/button/Button";
import {IAppState} from "../../redux/store";
import {update} from "../../redux/actions/Setup";
import {ISetupState} from "../../redux/reducers/Setup";
import UsersService from "../../model/service/security/UsersService";

interface IProps {
    user: IUser,
    masqueradeActive: boolean
    signOut: () => void
    disableMasquerade: () => void
    delegatedBy: IEmployee[]
    update: (changes: Partial<ISetupState>) => void
    currentEmployee?: IEmployee
}

interface IState {
}

class NavProfile extends React.Component<IProps, IState> {
    render() {
        const {user, masqueradeActive, delegatedBy, currentEmployee} = this.props
        return (
            <Dropdown placement="bottomRight" overlayStyle={{zIndex: 1040}} overlay={(
                <div className="nav-profile nav-dropdown" style={{minWidth: 280}}>
                    <div className={"nav-profile-header"}>
                        <Row align={"middle"} className={'flex-column'}>
                            <UserAvatar size={45} user={user}/>
                            {currentEmployee && currentEmployee.fullName}
                            <div style={{overflow: "hidden", textOverflow: "ellipsis", maxWidth: "calc(100% - 55px)"}}>
                                <div className={"text-muted"}>{user.username}</div>
                            </div>
                        </Row>
                        <ScrollContainer orientation={'horizontal'}>
                            {masqueradeActive && (

                                <Tag className={"mt-2"} color={"red"}>Přihlášení za jiného uživatele aktivní</Tag>
                            )}
                            {delegatedBy.length > 0 && (
                                <Tag className={"mt-2"} color={"yellow"}>
                                    <Row>
                                        <Col>
                                            Zástup za:
                                        </Col>
                                        <Col className={'pb-2'}>
                                            {delegatedBy.map(emp => (
                                                <EmployeeLabel employee={emp}/>
                                            ))}
                                        </Col>
                                    </Row>
                                </Tag>
                            )}
                        </ScrollContainer>
                    </div>
                    <div className="nav-profile-body">
                        <Menu>
                            {user.employees!.sort((a, b) => a.uuid === currentEmployee?.uuid ? -1 : 1).map((employee, i) => {
                                return (
                                    <Menu.Item key={'employee-' + i} style={{height: 'auto'}}>
                                        <Link to={'/app/company/employees/' + employee.id}>
                                            <Row justify={"space-between"}>
                                                <Col>
                                                    <Row align={'middle'} className={'h-100'}>
                                                        <TeamOutlined className="mr-3"/>
                                                        <div className={'d-flex flex-column'}>
                                                         <span className="font-weight-normal" style={{lineHeight: 'initial'}}>
                                                            {user.employees!.length > 1 ? employee.fullName : 'Karta zaměstnance'}
                                                        </span>
                                                            {user.employees.some(e => e.fullName === employee.fullName && e.uuid !== employee.uuid) && (
                                                                <span className="font-size-xs" style={{lineHeight: 'initial'}}>
                                                                {employee.personalNumber || employee.job?.name || employee.unit?.name}
                                                            </span>
                                                            )}
                                                        </div>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    {currentEmployee?.uuid === employee.uuid ? (
                                                        <Tooltip placement={'left'} title={<LocaleText code={'navigation.employee.switch'}
                                                                                    fallback={'Aktuální výchozí zaměstnanec'}/>}>
                                                            <Button onClick={e => {
                                                                e.preventDefault();
                                                                e.stopPropagation()
                                                            }} type={"success"}
                                                                    icon={<CheckOutlined/>} size={"extraSmall"}/>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip placement={'left'} title={<LocaleText code={'navigation.employee.switch'}
                                                                                    fallback={'Přepnout výchozího zaměstnance na tohoto'}/>}>
                                                            <Button
                                                                onClick={e => {
                                                                    this.setCurrentEmployee(employee, e);
                                                                }}
                                                                type={"info"} icon={<UserSwitchOutlined/>}
                                                                size={"extraSmall"}/>
                                                        </Tooltip>
                                                    )}
                                                </Col>
                                            </Row>

                                        </Link>
                                    </Menu.Item>
                                );
                            })}
                            <Menu.Item key={"edit"}>
                                <Link to={"/app/security/users/me"}>
                                    <SettingOutlined className="mr-3"/>
                                    <span className="font-weight-normal">{"Nastavení"}</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item>
                                <NavTheme className={'font-weight-normal'}/>
                            </Menu.Item>
                            <Menu.Item key={"help"}>
                                <Link to={"/app/help"}>
                                    <QuestionCircleOutlined className="mr-3"/>
                                    <span className="font-weight-normal">{"Nápověda"}</span>
                                </Link>
                            </Menu.Item>
                            {masqueradeActive && (
                                <Menu.Item key={"disableMasquerade"} onClick={() => this.props.disableMasquerade()}>
                                    <span>
                                      <UserSwitchOutlined className="mr-3"/>
                                      <span className="font-weight-normal">
                                          Zpět na originálního uživatele
                                      </span>
                                    </span>
                                </Menu.Item>
                            )}
                            <Menu.Item key={"signout"} onClick={() => this.props.signOut()}>
                                <span>
                                  <LogoutOutlined className="mr-3"/>
                                  <span className="font-weight-normal">
                                      <LocaleText code={"user.signOut"} fallback={'Odhlásit se'}/>
                                  </span>
                                </span>
                            </Menu.Item>
                        </Menu>
                    </div>
                </div>
            )} trigger={["click"]}>
                <div>
                    {masqueradeActive || delegatedBy.length ? (
                        <Tooltip placement={'bottom'} title={<div>
                            {masqueradeActive && <div>Přihlášení za jiného uživatelé je aktivní!</div>}
                            {delegatedBy.length && <div>Zástup je aktivní!</div>}
                        </div>}>
                            <Badge style={{backgroundColor: masqueradeActive ? '#ff6b72' : '#fadb14'}} count={"!"}>
                                <UserAvatar size={36} user={user}/>
                            </Badge>
                        </Tooltip>
                    ) : (
                        <UserAvatar size={36} user={user} className={'nav-icon'}/>
                    )}
                </div>
            </Dropdown>
        );
    }

    private setCurrentEmployee(employee: IEmployee, e: React.MouseEvent<HTMLElement>) {
        const {update, user} = this.props
        EmployeesService.getInstance().setCurrentEmployee(employee.uuid);
        update({currentEmployee: employee})
        user.id && employee.id && UsersService.resourceUpdateCurrentEmployee(user.id, employee.id)
        e.preventDefault();
        e.stopPropagation()
    }
}


const mapStateToProps = ({setup}: IAppState) => {
    const {user, masqueradeActive, delegatedBy, currentEmployee} = setup
    return {user, masqueradeActive, delegatedBy, currentEmployee}
}

export default connect(mapStateToProps, {signOut, disableMasquerade, update})(NavProfile)
